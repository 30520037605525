/* Common Imports */

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";

/* Component Imports */

import { SvgIcon, Typography, styled, useTheme } from "@mui/material";
import { useRouter } from "next/router";

/* Icons / Illustrations */

import DeveloperProjects from "../../../public/images/icons/home/developer_projects.svg";
import Rent from "../../../public/images/icons/home/rent.svg";
import RentArabic from "../../../public/images/icons/home/rent_arabic.svg";
import RentKannada from "../../../public/images/icons/home/rent_kannada.svg";
import Resale from "../../../public/images/icons/home/resale.svg";
import Sale from "../../../public/images/icons/home/sale.svg";
import SaleArabic from "../../../public/images/icons/home/sale_arabic.svg";
import SaleKannada from "../../../public/images/icons/home/sale_kannada.svg";
import {
	setSearchAnimationDetailsThunk,
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
} from "../../../redux-magic/thunks";

import {
	agricultural_options_active,
	commercial_options_active,
	industrial_options_active,
	residential_options_active,
	warehousing_options_active,
} from "../../../lib/data/search-filters/_e_property_type";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "space-between",
	width: "100%",
	gap: "0.5rem",
	padding: "0rem 0rem 0rem 0rem",
	margin: "1.25rem 0rem 1.25rem 0rem",
	[theme.breakpoints.up("md1190")]: {
		gap: "0.75rem",
		margin: "2rem 0rem 1.25rem 0rem",
	},
}));

const CardsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-around",
	alignItems: "space-between",
	flexWrap: "wrap",
	width: "100%",
	gap: "0.5rem",
}));

const CategoryCard = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	padding: "0.75rem 0.75rem 0.75rem 0.75rem",
	width: "48%",
	gap: "0rem",
	background: theme.palette.background.paper,
	borderRadius: "16px",
	cursor: "pointer",
	WebkitTapHighlightColor: "transparent",
	[theme.breakpoints.up("md1190")]: {
		width: "24%",
		padding: "1.5rem 1.5rem 1.5rem 1.5rem",
	},
	"@media (pointer: fine)": {
		transition: "all 0.2s ease",
		"&:hover": {
			// boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
			boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.05)",
			transform: "scale(1.02)",
		},
	},
}));

const TextColumn = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-start",
	width: "100%",
	gap: "0rem",
	[theme.breakpoints.up("md1190")]: {
		gap: "0.5rem",
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	lineHeight: "1rem",
	fontWeight: 500,
	color: theme.palette.text.primary,
	padding: "0rem 0rem 0rem 0.25rem",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.25rem",
		lineHeight: "1.5rem",
	},
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	lineHeight: "1.25rem",
	fontWeight: 600,
	color: theme.palette.text.primary,
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
}));

const SubText = styled(Typography)(({ theme }) => ({
	fontSize: "0.75rem",
	lineHeight: "1rem",
	color: theme.palette.text.secondary,
	[theme.breakpoints.up("md1190")]: {
		fontSize: "0.875rem",
	},
}));

const CategorySection = ({
	searchanimationsettings,
	dispatch,
	searchLocation,
}: {
	searchanimationsettings: StateInterface["search_animation_settings"];
	dispatch: Function;
	searchLocation: any;
}) => {
	const theme = useTheme();

	const router = useRouter();

	const category_cards = [
		{
			id: "sale",
			subtext: router.locale === "ar-AE" ? "عقارات" : router.locale === "kn-IN" ? "ಆಸ್ತಿಗಳ" : "Properties for",
			title: router.locale === "ar-AE" ? "للبيع" : router.locale === "kn-IN" ? "ಮಾರಾಟ" : "Sale",
			illustration: router.locale === "ar-AE" ? SaleArabic : router.locale === "kn-IN" ? SaleKannada : Sale,
		},
		{
			id: "rent",
			subtext: router.locale === "ar-AE" ? "عقارات" : router.locale === "kn-IN" ? "ಆಸ್ತಿಗಳು" : "Properties for",
			title: router.locale === "ar-AE" ? "للايجار" : router.locale === "kn-IN" ? "ಬಾಡಿಗೆಗೆ" : "Rent",
			illustration: router.locale === "ar-AE" ? RentArabic : router.locale === "kn-IN" ? RentKannada : Rent,
		},
		{
			id: "resale",
			subtext: router.locale === "ar-AE" ? "عقارات" : router.locale === "kn-IN" ? "ಆಸ್ತಿಗಳ" : "Properties for",
			title: router.locale === "ar-AE" ? "إعادة البيع" : router.locale === "kn-IN" ? "ಮರುಮಾರಾಟ" : "Resale",
			illustration: Resale,
		},
		{
			id: "projects",
			subtext: router.locale === "ar-AE" ? "جديدة" : router.locale === "kn-IN" ? "ಹೊಸ" : "New",
			title: router.locale === "ar-AE" ? "مشاريع" : router.locale === "kn-IN" ? "ಯೋಜನೆಗಳು" : "Projects",
			illustration: DeveloperProjects,
		},
	];

	const handleCardClick = (category: string, searchLocation: any) => {
		const { title, coordinates } = searchLocation.value;

		const categoryMapping: { [key: string]: { transactionType: string; additionalParams?: string } } = {
			sale: {
				transactionType: "buy",
				additionalParams: "&property_category=residential&property_type=Apartment",
			},
			rent: {
				transactionType: "rent",
				additionalParams: "&property_category=residential&property_type=Apartment",
			},
			resale: {
				transactionType: "resale",
				additionalParams: "&property_category=residential&property_type=Apartment",
			},
			projects: {
				transactionType: "buy",
				additionalParams: "&property_category=residential&property_type=Apartment&listed_by=developer",
			},
		};

		const categoryInfo = categoryMapping[category];

		if (!categoryInfo) return;

		const { transactionType, additionalParams } = categoryInfo;

		const callbackUrl = encodeURIComponent(
			`${process.env.PRODUCTION_URL}search?search_type=properties&location=${title},${coordinates[0]},${coordinates[1]}&transaction_type=${transactionType}${additionalParams || ""}&locale=${router.locale || "en-In"}`,
		);

		const url = `${process.env.PRODUCTION_URL}loading.html?callbackUrl=${callbackUrl}`;

		window.location.href = url;
	};

	const handleCardFIrstTimeClick = (category: string) => {
		const categoryMapping: {
			[key: string]: {
				transactionType: string;
				propertyCategory: string;
				titleCategory: string;
				propertyType: string;
				titleType: string;
				listedBy?: string;
			};
		} = {
			sale: {
				transactionType: "buy",
				propertyCategory: "residential",
				titleCategory: "Residential",
				propertyType: "Apartment",
				titleType: "Apartment",
			},
			rent: {
				transactionType: "rent",
				propertyCategory: "residential",
				titleCategory: "Residential",
				propertyType: "Apartment",
				titleType: "Apartment",
			},
			resale: {
				transactionType: "resale",
				propertyCategory: "residential",
				titleCategory: "Residential",
				propertyType: "Apartment",
				titleType: "Apartment",
			},
			projects: {
				transactionType: "buy",
				propertyCategory: "residential",
				titleCategory: "Residential",
				propertyType: "Apartment",
				titleType: "Apartment",
				listedBy: "developer",
			},
		};

		const categoryInfo = categoryMapping[category];

		if (!categoryInfo) return;

		const { transactionType, propertyCategory, propertyType, listedBy, titleCategory, titleType } = categoryInfo;

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "search_type",
				newOptions: [
					{
						id: "properties",
						title: "Properties",
						value: "properties",
					},
				],
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "search_type",
				isApplied: true,
			}),
		);
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "transaction_type",
				newOptions: [
					{
						id: transactionType,
						title: transactionType.charAt(0).toUpperCase() + transactionType.slice(1),
						value: transactionType,
					},
				],
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "transaction_type",
				isApplied: true,
			}),
		);
		/*

			*	prtoperty category

		*/

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "property_category",
				newOptions: [
					{
						id: propertyCategory,
						title: titleCategory,
						value: propertyCategory,
					},
				],
			}),
		);
		dispatch(
			updateSearchFilterVisibilityThunk({
				searchFilterId: "property_category",
				isVisible: true,
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "property_category",
				isApplied: true,
			}),
		);
		/*

			* property type

		*/
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "property_type",
				newOptions: [
					{
						id: propertyType,
						title: titleType,
						value: propertyType,
					},
				],
			}),
		);
		dispatch(
			updateSearchFilterVisibilityThunk({
				searchFilterId: "property_type",
				isVisible: true,
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "property_type",
				isApplied: true,
			}),
		);

		if (category === "projects" && listedBy) {
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "listed_by",
					newOptions: [
						{
							id: listedBy,
							title: "Developer",
							value: listedBy,
						},
					],
				}),
			);
			dispatch(
				updateSearchFilterVisibilityThunk({
					searchFilterId: "listed_by",
					isVisible: true,
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "listed_by",
					isApplied: true,
				}),
			);
		}
		/*

		* disable service type and subtype

		*/
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "service_subtype",
				isApplied: false,
			}),
		);
		dispatch(
			updateSearchFilterVisibilityThunk({
				searchFilterId: "service_subtype",
				isVisible: false,
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "service_type",
				isApplied: false,
			}),
		);
		dispatch(
			updateSearchFilterVisibilityThunk({
				searchFilterId: "service_type",
				isVisible: false,
			}),
		);

		const propertyTypeOptions =
			propertyCategory === "residential"
				? residential_options_active
				: propertyCategory === "commercial"
					? commercial_options_active
					: propertyCategory === "agricultural"
						? agricultural_options_active
						: propertyCategory === "warehousing"
							? warehousing_options_active
							: propertyCategory === "industrial"
								? industrial_options_active
								: residential_options_active;

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: "property_type",
				newOptions: propertyTypeOptions,
			}),
		);
	};

	return (
		<MainContainer
			sx={{
				direction: router.locale === "ar-AE" ? "rtl" : "ltr",
			}}
		>
			<Heading
				id="home_mobile_what_are_you_looking_for_heading"
				variant="h6"
				sx={{
					fontWeight: router.locale === "ar-AE" ? 600 : 500,
				}}
			>
				{router.locale === "ar-AE"
					? "ما الذي تبحث عنه؟"
					: router.locale === "kn-IN"
						? "ನೀವು ಏನು ಹುಡುಕುತ್ತಿದ್ದೀರಿ?"
						: "What are you looking for?"}
			</Heading>

			<CardsContainer>
				{category_cards.map((category, index) => {
					return (
						<CategoryCard
							id={"home_mobile_property_category_section_" + category.id}
							key={index}
							onClick={() => {
								if (!searchLocation) {
									dispatch(
										setSearchAnimationDetailsThunk({
											StartAnimate: searchanimationsettings?.StartAnimate as boolean,
											TriggerSearch: searchanimationsettings?.TriggerSearch as boolean,
											ResetSearch: searchanimationsettings?.ResetSearch as boolean,
											PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
											HomeAccordion: false,
											OpenLocationDrawer: true,
										}),
									);
									handleCardFIrstTimeClick(category.id);
								} else {
									handleCardClick(category.id, searchLocation);
								}
							}}
						>
							<TextColumn>
								<SubText
									id={"home_mobile_property_category_section_" + category.id}
									variant="subtitle1"
								>
									{category.subtext}
								</SubText>
								<Title
									id={"home_mobile_property_category_section_" + category.id}
									variant="subtitle1"
								>
									{category.title}
								</Title>
							</TextColumn>

							<SvgIcon
								id={"home_mobile_property_category_section_" + category.id}
								component={category.illustration}
								inheritViewBox={false}
								fill="none"
								viewBox={
									index === 0 ? "0 0 42 42" : index === 1 ? "0 0 62 61" : index === 2 ? "0 0 35 42" : "0 0 42 42"
								}
								sx={{
									width: "3.125rem",
									height: "1.875rem",
									[theme.breakpoints.up("md1190")]: {
										width: "4.125rem",
										height: "2.875rem",
									},
								}}
							/>
						</CategoryCard>
					);
				})}
			</CardsContainer>
		</MainContainer>
	);
};

export default CategorySection;
