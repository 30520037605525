/* Component Imports */

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { Divider, MenuItem, Select, Snackbar, SnackbarOrigin, styled, Tab, Tabs, useTheme } from "@mui/material";
import { useRouter } from "next/router";
import React from "react";
import StateInterface from "../../../../redux-magic/state-interface";
import {
	setSearchAnimationDetailsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
} from "../../../../redux-magic/thunks";
import BeegruButton from "../../../common-components/buttons/BeegruButton";
import LocationAutoComplete from "./LocationAutoComplete";
/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "flex-start",
	justifyContent: "flex-start",
	width: "100%",
	background: "#FFF",
	borderRadius: "1rem",
	marginTop: "2.375rem",
	padding: "0.5rem 1rem 0.5rem 1rem",
	gap: "1rem",
}));

const SearchTypeTabs = styled(Tabs)({
	display: "flex",
	justifyContent: "center",
	"& .MuiTabs-flexContainer": {
		gap: "3rem",
	},
});

const SearchTypeTab = styled(Tab)(({ theme }) => ({
	display: "flex",
	textTransform: "capitalize",

	lineHeight: "1.5rem",
	"&.MuiTab-root": {
		color: theme.palette.text.secondary,
		fontWeight: 500,
	},
	"&.Mui-selected": {
		color: "#B05911",
		fontWeight: 600,
	},
}));

const SnackbarDiv = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	gap: "8px",
}));

interface State extends SnackbarOrigin {
	open: boolean;
}

const SearchNew = ({
	search_filter_state,
	dispatch,
	searchanimationsettings,
}: {
	search_filter_state: StateInterface["search_filters_state"];
	dispatch: Function;
	searchanimationsettings: StateInterface["search_animation_settings"];
}) => {
	const theme = useTheme();

	const [initialLoad, setInitialLoad] = React.useState(true);

	const router = useRouter();
	let label = "HSR Layout";
	let lat = 12.9121181;
	let lon = 77.6445548;

	const defaultUrlpath = encodeURIComponent(
		`${process.env.PRODUCTION_URL || ""}/search?search_type=properties&location=${label},${lon},${lat}&transaction_type=rent&property_category=residential&property_type=Apartment&locale=${"en-IN"}`,
	);

	/*

    * No Location Snackbar states

    */

	const [state, setState] = React.useState<State>({
		open: false,
		vertical: "top",
		horizontal: "center",
	});

	const { vertical, horizontal, open } = state;

	const handleClose = () => {
		setState({ ...state, open: false });
	};

	/*

    * Search Type Tabs and handler function

    */

	const [value, setValue] = React.useState("properties");

	const handleChangeSearchTypeTabs = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
		/*

		* newValue for services and properties

		*/
		if (newValue === "properties") {
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "search_type",
					isApplied: true,
				}),
			),
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "search_type",
						newOptions: [
							{
								id: newValue,
								title: newValue.charAt(0).toUpperCase() + newValue.slice(1),
								value: newValue,
							},
						],
					}),
				);
			/*

						* updating service is applied and is visible

						*/
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "service_type",
					newOptions: [],
				}),
			),
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "service_subtype",
						newOptions: [],
					}),
				),
				dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: false }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_type", isApplied: false }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_subtype", isApplied: false }));

			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_category", isVisible: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: false }));
			/*

						* updating properties is applied and is visible

						*/
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "property_category",
					newOptions: [
						{
							id: "residential",
							title: "Residential",
							value: "residential",
						},
					],
				}),
			),
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "property_type",
						newOptions: [
							{
								id: "apartment",
								title: "Apartment",
								value: "Apartment",
							},
						],
					}),
				),
				dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: true }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: true }));

			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: true }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: true }));
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "transaction_type",
					newOptions: [
						{
							id: transactionType,
							title: transactionType.charAt(0).toUpperCase() + transactionType.slice(1),
							value: transactionType,
						},
					],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "transaction_type",
					isApplied: true,
				}),
			);
		} else if (newValue === "agents") {
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "transaction_type",
					newOptions: [],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "transaction_type",
					isApplied: false,
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "search_type",
					isApplied: true,
				}),
			),
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "search_type",
						newOptions: [
							{
								id: "business_profiles",
								title: "Business Profiles",
								value: "business_profiles",
							},
						],
					}),
				),
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "business_profile_type",
						newOptions: [
							{
								id: "agents",
								title: "Agents",
								value: "agents",
							},
						],
					}),
				),
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "property_category",
						newOptions: [],
					}),
				),
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "property_type",
						newOptions: [],
					}),
				),
				dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: false }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: false }));

			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_status", isVisible: false }));

			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_category", isVisible: false }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_subtype", isApplied: false }));

			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: false }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_type", isApplied: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: false }));

			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "business_profile_type", isApplied: true }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "business_profile_type", isVisible: true }));
		} else if (newValue === "services") {
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "search_type",
					isApplied: true,
				}),
			),
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "search_type",
						newOptions: [
							{
								id: newValue,
								title: newValue.charAt(0).toUpperCase() + newValue.slice(1),
								value: newValue,
							},
						],
					}),
				);
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "property_category",
					newOptions: [],
				}),
			),
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "property_type",
						newOptions: [],
					}),
				),
				dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: false }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: false }));

			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: true }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_type", isApplied: true }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_subtype", isApplied: true }));

			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_category", isVisible: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: true }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: true }));

			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: false }));

			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "business_profile_type", isApplied: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "business_profile_type", isVisible: false }));
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "transaction_type",
					newOptions: [],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "transaction_type",
					isApplied: false,
				}),
			);
		}
	};

	const [transactionType, setTransactionType] = React.useState("buy");

	const [openTransactionType, setOpenTransactionType] = React.useState(false);

	const handleChange = (event: { target: { value: string } }) => {
		setOpenTransactionType(false);
		setTransactionType(event.target.value);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "transaction_type",
				isApplied: true,
			}),
		);
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "transaction_type",
				newOptions: [
					{
						id: event.target.value,
						title: event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1),
						value: event.target.value,
					},
				],
			}),
		);
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "property_category",
				newOptions: [
					{
						id: "residential",
						title: "Residential",
						value: "residential",
					},
				],
			}),
		),
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "property_type",
					newOptions: [
						{
							id: "apartment",
							title: "Apartment",
							value: "Apartment",
						},
					],
				}),
			),
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: true }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: true }));

		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: true }));
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: true }));
	};

	return (
		<React.Fragment>
			<Snackbar
				anchorOrigin={{ vertical, horizontal }}
				open={open}
				onClose={handleClose}
				message={
					<SnackbarDiv>
						<WarningAmberOutlinedIcon sx={{ color: "#5F2B01" }} />
						{router.locale === "ar-AE"
							? "يرجى إدخال الموقع"
							: router.locale === "kn-IN"
								? "ದಯವಿಟ್ಟು ಸ್ಥಳವನ್ನು ನಮೂದಿಸಿ"
								: "Please enter a location"}
					</SnackbarDiv>
				}
				key={`${vertical}-${horizontal}`}
				sx={{ "& .MuiSnackbarContent-root": { backgroundColor: "#FDF0E5", color: "#5F2B01" } }}
			/>

			<MainContainer>
				<div>
					<SearchTypeTabs
						value={value}
						onChange={handleChangeSearchTypeTabs}
						variant="standard"
						TabIndicatorProps={{
							style: { backgroundColor: "#B05911" },
						}}
					>
						<SearchTypeTab
							disableFocusRipple
							disableRipple
							value="properties"
							label="Properties"
						/>
						<SearchTypeTab
							disableFocusRipple
							disableRipple
							value="services"
							label="Services"
						/>
						<SearchTypeTab
							disableFocusRipple
							disableRipple
							value="agents"
							label="Agents"
						/>
					</SearchTypeTabs>
				</div>
				<div
					style={{
						display: "flex",
						width: "100%",
						justifyContent: "flex-start",
						alignItems: "center",
						gap: "0.5rem",
					}}
				>
					{search_filter_state?.search_type.selected_options[0].id === "properties" && (
						<>
							<Select
								id="home_desktop_transaction_type_select"
								value={transactionType}
								onChange={handleChange}
								onOpen={() => setOpenTransactionType(true)}
								onClose={() => setOpenTransactionType(false)}
								displayEmpty
								IconComponent={() => (openTransactionType ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
								MenuProps={{
									PaperProps: {
										sx: {
											boxShadow: "none",
											border: "1px solid #ddd",
										},
									},
									disableScrollLock: true,
								}}
								sx={{
									width: "15%",
									backgroundColor: "white",
									"& .MuiOutlinedInput-notchedOutline": {
										border: "none",
									},
									"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
										border: "none",
									},
									"& .MuiSelect-select": {
										display: "flex",
										alignItems: "center",
										padding: "0rem 0rem 0rem 0.25rem",
									},
									"& .MuiInputBase-root": {
										display: "flex",
										alignItems: "center",
									},
								}}
							>
								<MenuItem
									id="home_desktop_transaction_type_buy"
									value="buy"
								>
									Sale
								</MenuItem>
								<MenuItem
									id="home_desktop_transaction_type_rent"
									value="rent"
								>
									Rent
								</MenuItem>
								<MenuItem
									id="home_desktop_transaction_type_resale"
									value="resale"
								>
									Resale
								</MenuItem>
							</Select>
							<Divider
								orientation="vertical"
								variant="middle"
								flexItem
							/>
						</>
					)}

					<div
						style={{
							display: "flex",
							width: "100%",
							gap: "1rem",
						}}
					>
						<LocationAutoComplete
							search_id="location"
							dispatch={dispatch}
							search_filter_state={search_filter_state.location}
						/>

						<BeegruButton
							id={"home_desktop_search_button"}
							variant="contained"
							size="large"
							onClick={() => {
								if (!search_filter_state.location.is_applied) {
									setState({
										open: true,
										vertical: "top",
										horizontal: "center",
									});
								} else {
									router
										.push("/search", "", { shallow: true, locale: router.locale })
										.then(() => {
											dispatch(
												setSearchAnimationDetailsThunk({
													StartAnimate: true,
													TriggerSearch: true,
													ResetSearch: false,
													PropertySubCategory: searchanimationsettings.PropertySubCategory,
													HomeAccordion: false,
													OpenLocationDrawer: false,
												}),
											);
										})
										.catch((error) => {
											console.error("Navigation error:", error);
										});
								}
							}}
						>
							{router.locale === "ar-AE" ? "بحث" : router.locale === "kn-IN" ? " ಹುಡುಕಿ" : "Search"}
						</BeegruButton>
					</div>
				</div>
			</MainContainer>
		</React.Fragment>
	);
};

export default SearchNew;
